import styled from "styled-components";

import {
	TABLET_BP,
	MOBILE_L_BP,
	FONT_SIZE_22,
	FONT_SIZE_18,
} from "@website-builder/utilities-index";

export const CardsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 230px;
	width: min-content;
`;

export const Header = styled.h2`
	margin: 0;
	margin-bottom: 10px;
	font-size: ${FONT_SIZE_22};
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		text-align: center;
		font-size: ${FONT_SIZE_18};
	}
`;

export const SubHeader = styled.p`
	margin: 0;
	margin-bottom: 25px;
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		text-align: center;
	}
`;
