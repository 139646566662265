import React, { useState, useEffect } from "react";
import { Container, CardsWrapper, Header, SubHeader } from "./styles";
import { MentorCard } from "../../Elements";
import { buildMentorProfiles } from "./transform";
import { getDataSources } from "@website-builder/services";

const MentorList = (props) => {
	let { heading, subHeading, list, wrapperEmpty, wrapper = [] } = props;
	let [reusableMentorList, setReusableMentorList] = useState([]);
	useEffect(() => {
		if (process.env.GATSBY_TEMPLATE === "PREVIEW" && !wrapperEmpty) {
			getDataSources("103937", "mentor-profiles", true).then((res) => {
				let newList = buildMentorProfiles(wrapper, list, res);
				setReusableMentorList(newList);
			});
		}
	}, []);

	const getMentor = () => {
		list =
			process.env.GATSBY_TEMPLATE === "PREVIEW" &&
			!wrapperEmpty &&
			!!reusableMentorList
				? reusableMentorList
				: list;

		return list.map(
			(
				{ avatar, name, designation, component, companyLogo, gridClass },
				index,
			) => {
				if (component) {
					return (
						<Container key={`${component}-${index}`} className={gridClass}>
							<MentorCard
								avatar={avatar}
								name={name}
								designation={designation}
								companyLogo={companyLogo}
							/>
						</Container>
					);
				} else {
					return null;
				}
			},
		);
	};
	return (
		<section className="row">
			{heading && <Header>{heading}</Header>}
			{subHeading && <SubHeader>{subHeading}</SubHeader>}
			<CardsWrapper className="row">{getMentor()}</CardsWrapper>
		</section>
	);
};

export default MentorList;
